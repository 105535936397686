// src/routes.tsx
import React from 'react';
import { useRoutes } from 'react-router-dom';
import MediaPlayer from './components/MediaPlayer/MediaPlayer';

const AppRoutes = () => {
  const routes = useRoutes([
    { path: '/:branchCode', element: <MediaPlayer /> },
  ]);
  return routes;
};

export default AppRoutes;