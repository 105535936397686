import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import './MediaPlayer.css';
import { MediaResponse, MediaItem } from '../../models/MediaResponse';

const MediaPlayer: React.FC = () => {
    const { branchCode } = useParams<{ branchCode: string }>();
    const [media, setMedia] = useState<MediaItem[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [intervalDuration, setIntervalDuration] = useState(10000); // Default interval
    const [reloadInterval, setReloadInterval] = useState(600000); // Default reload interval
    //const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiKeyHeader = process.env.REACT_APP_API_KEY_HEADER;

    const headers = useMemo(() => {
        const hdrs: { [key: string]: string } = {};
        hdrs[`${apiKeyHeader}`] = `${apiKey}`;
        return hdrs;
    }, [apiKey, apiKeyHeader]);

    const fetchMedia = useCallback(async () => {
        if (!branchCode) return;
        try {
            const response = await fetch(`${apiUrl}/${branchCode}`, {
                headers: headers,
            });
            const data: MediaResponse = await response.json();
            setMedia(data.media); // Assuming the API returns an array of media objects with type and url properties
            setIntervalDuration(data.interval); // Set interval duration from the response
            setReloadInterval(data.reloadInterval); // Set reload interval from the response
            setCurrentIndex(0); // Reset the current index
        } catch (error) {
            console.error('Error fetching media:', error);
        }
    }, [branchCode, apiUrl, headers]);

    useEffect(() => {
        fetchMedia();

        const reloadTimer = setInterval(() => {
            fetchMedia();
        }, reloadInterval);

        return () => clearInterval(reloadTimer);
    }, [fetchMedia, reloadInterval]);

    useEffect(() => {
        const switchMedia = () => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
        };

        if (media[currentIndex]?.type === 'video' && videoRef.current) {
            videoRef.current.onended = switchMedia;
        } else {
            const intervalId = setInterval(switchMedia, intervalDuration);
            return () => clearInterval(intervalId);
        }
    }, [media, currentIndex, intervalDuration]);

    const renderMedia = useCallback(() => {
        if (media.length === 0) return null;

        const currentMedia = media[currentIndex];

        if (currentMedia.type === 'image') {
            return (
                <div
                    className="media-container"
                    style={{
                        backgroundImage: `url(${currentMedia.url})`,
                    }}
                ></div>
            );
        } else if (currentMedia.type === 'video') {
            return (
                <div className="media-container video">
                    <video
                        ref={videoRef}
                        src={currentMedia.url}
                        autoPlay
                        muted
                        loop={media.length === 1}
                    />
                </div>
            );
        }
    }, [media, currentIndex]);

    return <>{renderMedia()}</>;
};

export default MediaPlayer;
